/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../core/Error.js";
import { makeHandle as e } from "../core/handleUtils.js";
import { createResolver as n } from "../core/promiseUtils.js";
import { beforeLocaleChange as s, getLocale as r } from "./locale.js";
const o = /^([a-z]{2})(?:[-_]([A-Za-z]{2}))?$/,
  i = {
    ar: !0,
    bg: !0,
    bs: !0,
    ca: !0,
    cs: !0,
    da: !0,
    de: !0,
    el: !0,
    en: !0,
    es: !0,
    et: !0,
    fi: !0,
    fr: !0,
    he: !0,
    hr: !0,
    hu: !0,
    id: !0,
    it: !0,
    ja: !0,
    ko: !0,
    lt: !0,
    lv: !0,
    nb: !0,
    nl: !0,
    pl: !0,
    "pt-BR": !0,
    "pt-PT": !0,
    ro: !0,
    ru: !0,
    sk: !0,
    sl: !0,
    sr: !0,
    sv: !0,
    th: !0,
    tr: !0,
    uk: !0,
    vi: !0,
    "zh-CN": !0,
    "zh-HK": !0,
    "zh-TW": !0
  };
function a(t) {
  return t in i;
}
const c = [],
  d = new Map();
function l(t) {
  for (const e of d.keys()) _(t.pattern, e) && d.delete(e);
}
function u(t) {
  return c.includes(t) || (l(t), c.unshift(t)), e(() => {
    const e = c.indexOf(t);
    e > -1 && (c.splice(e, 1), l(t));
  });
}
async function h(t) {
  const e = r();
  d.has(t) || d.set(t, m(t, e));
  const n = d.get(t);
  return n && (await p.add(n)), n;
}
function f(t) {
  if (!o.test(t)) return null;
  const e = o.exec(t);
  if (null === e) return null;
  const [, n, s] = e,
    r = n + (s ? "-" + s.toUpperCase() : "");
  return a(r) ? r : a(n) ? n : null;
}
async function m(e, n) {
  const s = [];
  for (const t of c) if (_(t.pattern, e)) try {
    return await t.fetchMessageBundle(e, n);
  } catch (r) {
    s.push(r);
  }
  if (s.length) throw new t("intl:message-bundle-error", `Errors occurred while loading "${e}"`, {
    errors: s
  });
  throw new t("intl:no-message-bundle-loader", `No loader found for message bundle "${e}"`);
}
function _(t, e) {
  return "string" == typeof t ? e.startsWith(t) : t.test(e);
}
s(() => {
  d.clear();
});
const p = new class {
    constructor() {
      this._numLoading = 0, this._dfd = null;
    }
    async waitForAll() {
      this._dfd && (await this._dfd.promise);
    }
    add(t) {
      return this._increase(), t.then(() => this._decrease(), () => this._decrease()), this.waitForAll();
    }
    _increase() {
      this._numLoading++, this._dfd || (this._dfd = n());
    }
    _decrease() {
      this._numLoading = Math.max(this._numLoading - 1, 0), this._dfd && 0 === this._numLoading && (this._dfd.resolve(), this._dfd = null);
    }
  }(),
  g = {
    cache: d,
    loaders: c
  };
export { h as fetchMessageBundle, i as messageBundlesLocaleMap, f as normalizeMessageBundleLocale, u as registerMessageBundleLoader, g as test };